import React from 'react'

const Footer = () => {

    return (
        
	<footer>
	
    <div className="container">
        <div className="row">
            <div className="col-sm-6 col-md-4">
                <div className="" data-wow-delay="0.1s">
                <div className="widget">
                    <h5>Acerca de Fisiothera+</h5>
                    <p>
                        Somos una clínica especializada en Fisioterapia y Rehabilitación, que nace con el propósito de brindarle al paciente
                        bienestar físico a través de diferentes técnicas de comprobada eficacia en un ambiente cómodo y seguro.
                    </p>
                </div>
                </div>
                <div className="" data-wow-delay="0.1s">
                <div className="widget">
                    <h5>Información</h5>
                    <ul>
                        <li><a href="#">Clínica</a></li>
                        <li><a href="#">Inicio</a></li>
                        <li><a href="#">Servicios</a></li>
                        <li><a href="#">Instalaciones</a></li>
                        <li><a href="#">Testimonios</a></li>
                    </ul>
                </div>
                </div>
            </div>
            <div className="col-sm-6 col-md-4">
                <div className="" data-wow-delay="0.1s">
                <div className="widget">
                    <h5>Fisiothera+</h5>
                    <p> 
                        Le acompañaremos durante todo su proceso de recuperación, revalorando su tratamiento constatemente y brindandole
                        consejos para su óptima recuperación.
                    </p>
                    <ul>
                        <li>
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-circle fa-stack-2x"></i>
                                <i className="fa fa-calendar-o fa-stack-1x fa-inverse"></i>
                            </span> Lunes - Viernes de 8:00am a 18:00pm
                        </li>
                        <li>
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-circle fa-stack-2x"></i>
                                <i className="fa fa-phone fa-stack-1x fa-inverse"></i>
                            </span> +52 777-449-76-11
                        </li>
                        <li>
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-circle fa-stack-2x"></i>
                                <i className="fa fa-envelope-o fa-stack-1x fa-inverse"></i>
                            </span> fisiothera310@gmail.com
                        </li>

                    </ul>
                </div>
                </div>
            </div>
            <div className="col-sm-6 col-md-4">
                <div className="" data-wow-delay="0.1s">
                <div className="widget">
                    <h5>Ubicación</h5>
                    <p>Col. Ampliación Lázaro cárdenas del Río Calle: Francisco I madero, 62507 Cuernavaca, Mor.</p>		
                    
                </div>
                </div>
                <div className="" data-wow-delay="0.1s">
                <div className="widget">
                    <h5>Siguenos</h5>
                    <ul className="company-social">
                        <li className="social-facebook">
                            <a href="https://web.facebook.com/TerapiaFisica310/?ref=page_internal" target={'_blank'}>
                                <i className="fa fa-facebook"></i>
                            </a>
                        </li>
                        <li className="social-dribble">
                            <a href="https://wa.me/7774497611?text=%C2%A1Hola!%20buen%20día,%20me%20gustaría%20solicitar%20informes%20acerca%20de..." target={'_blank'}>
                                <img style={{ borderRadius:'3px'}} src='/img/whatsapp.png' height={ '39px' } width={'39px'} />
                            </a>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
        </div>	
    </div>
    <div className="sub-footer">
    <div className="container">
        <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="" data-wow-delay="0.1s">
                <div className="text-left">
                <p>&copy;Copyright 2022 - Fisiothera+. Todos los derechos reservados.</p>
                </div>
                </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="" data-wow-delay="0.1s">
                <div className="text-right">
                    {
                        //<p><a href="http://bootstraptaste.com/">Bootstrap Themes</a> by BootstrapTaste</p>

                    }
                </div>
                </div>
            </div>
        </div>	
    </div>
    </div>
</footer>
    );
}

export default Footer;