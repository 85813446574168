import React from 'react';

const Facilities = ()=> {
    
    return (
		<>
        <section id="nuestras-instalaciones" className="home-section paddingbot-60">
			<div className="container marginbot-50">
				<div className="row">
					<div className="col-lg-8 col-lg-offset-2">
						<div className="" data-wow-delay="0.1s">
						<div className="section-heading text-center">
						<h2 className="h-bold">Nuestras Instalaciones</h2>
						<p>Contamos con la mejor tecnología e instalaciones para brindar el mejor servicio posible a nuestros clientes.</p>
						</div>
						</div>
					</div>
				</div>
			</div>		

			
			<div className="carousel-reviews-facilities broun-block">
				<div className="container">
					<div className="row">
						<div id="carousel-reviews-facilities" className="carousel slide" data-ride="carousels">
						
							<div className="carousel-inner">
								<div className="item active">
									<div className="col-md-4 col-sm-6">
										<div className="person-text rel text-light" style={{ color: 'black'}}>					
											<img src="img/facilities/electroterapia.jpeg" alt="" className="person" />
											<a style={{ color : 'black'}} title="" href="/">Electroestimulación</a>
											<span>Es un tratamiento fisioterapéutico que utiliza la corriente eléctrica para estimular zonas del organismo afectadas por el dolor nervioso, inflamaciones, músculos atrofiados y lesiones osteomusculares, al ser combinada con otros tratamientos más tradicionales (masajes, estiramientos, ejercicios de rehabilitación) permite lograr una recuperación más veloz. </span>
										</div>
									</div>
									<div className="col-md-4 col-sm-6 hidden-xs">
										<div className="person-text rel text-light" style={{ color : 'black'}}>
											<img src="img/facilities/ultrasonido terapeutico.jpeg" alt="" className="person" />
											<a title="" href="/" style={{ color : 'black'}}>Ultrasonido Terapéutico</a>
											<span>Funciona al conducir ondas sonoras dentro del tejido para ayudar a aliviar el dolor, la inflamación, la capacidad de regeneración tisular, efecto antiálgico y espasmolítico, disminución de la rigidez articular y de la contractura.</span>
										</div>
									</div>
									<div className="col-md-4 col-sm-6 hidden-sm hidden-xs">
										<div className="person-text rel text-light" style={{ color : 'black'}}>
											<img src="img/facilities/mecanoterapia.jpg" alt="" className="person" />
											<a title="" href="/" style={{ color : 'black'}}>Mecanoterapia</a>
											<span>Es la utilización terapéutica e higiénica de aparatos mecánicos destinados a provocar y dirigir movimientos corporales regulados en su fuerza, trayectoria y amplitud.</span>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="col-md-4 col-sm-6">
										<div className="person-text rel text-light" style={{ color : 'black'}}>
											<img src="img/facilities/alberca.png" alt="" className="person" />
											<a title="" href="/" style={{ color : 'black'}}>Hidroterapia</a>
											<span>Es la utilización terapéutica del agua por sus propiedades físicas; podemos definirla también como la rama de la hidrología que estudia la aplicación externa del agua sobre el cuerpo humano, siempre que sea con fines terapéuticos y principalmente por sus efectos mecánicos y térmicos.</span>
										</div>
									</div>
									<div className="col-md-4 col-sm-6 hidden-xs">
										<div className="person-text rel text-light" style={{ color : 'black'}}>
											<img src="img/facilities/masoterapia2.jpg" alt="" className="person" />
											<a title="" href="/" style={{ color : 'black'}}>Masaje/Masoterapia</a>
											<span>Se trata del conjunto de manipulaciones, practicadas normalmente sin ayuda de instrumentos, sobre una parte o totalidad del organismo, con el objeto de movilizar los tejidos para provocar en el organismo modificaciones de orden directa o refleja que se traduzcan en efectos terapéuticos. </span>
										</div>
									</div>
									<div className="col-md-4 col-sm-6 hidden-sm hidden-xs">
										<div className="person-text rel text-light" style={{ color : 'black'}}>
											<img src="img/facilities/termoterapia.jpeg" alt="" className="person" />
											<a title="" href="/" style={{ color : 'black'}}>Termoterapia</a>
											<span>Consiste en el tratamiento de una lesión mediante el uso de calor y con ella se logra aplicar un efecto terapéutico y relajante
												Los efectos de la termoterapia son:
												<br/>•	Efecto vasodilatador. En este caso se aumenta el flujo de la sangre a la zona, con efecto analgésico.
												<br/>•	Sedante. El calor de larga duración tiene un efecto sedante, que favorece la relajación muscular, aliviando la fatiga
											</span>
										</div>
									</div>
								</div>

								<div className="item">
									<div className="col-md-4 col-sm-6">
										<div className="person-text rel text-light" style={{ color : 'black'}}>
											<img src="img/facilities/cromoterapiaa.png" alt="" className="person" />
											<a title="" href="/" style={{ color : 'black'}}>Cromoterapia</a>
											<span>Es un método de armonización y de ayuda a la curación natural de ciertas enfermedades por medio de los colores, se realiza para buscar el bienestar y el equilibrio de cuerpo y la mente, con el objetivo de mejorar las patologías que afecten al paciente.</span>
										</div>
									</div>
									<div className="col-md-4 col-sm-6 hidden-xs">
										<div className="person-text rel text-light" style={{ color : 'black'}}>
											<img src="img/facilities/cinesterapia.jpg" alt="" className="person" />
											<a title="" href="/" style={{ color : 'black'}}>Cinesiterapia</a>
											<span>Es la parte de la fisioterapia que se ocupa del control de las posturas y uso del movimiento con fines terapéuticos para evitar que por una inmovilización se genere una inmovilidad.</span>
										</div>
									</div>
									<div className="col-md-4 col-sm-6 hidden-sm hidden-xs">
										<div className="person-text rel text-light" style={{ color : 'black'}}>
											<img src="img/facilities/infra.jpg" alt="" className="person" />
											<a title="" href="/" style={{ color : 'black'}}>Infrarrojo</a>
											<span>
											Ayuda con la cicatrización en quemaduras, injertos de piel, heridas infectadas o amputaciones, aumentando el ritmo de regeneración de la piel y otros tejidos por su efecto antiinflamatorio, antiespasmódico, anticontracturante, aumento de la permeabilidad de la membrana celular. 
											</span>
										</div>
									</div>
								</div>	

								<div className="item">
									<div className="col-md-4 col-sm-6">
										<div className="person-text rel text-light" style={{ color : 'black'}}>
											<img src="img/facilities/tina de hidro.jpeg" alt="" className="person" />
											<a title="" href="/" style={{ color : 'black'}}>Hidroterapia en tina de hidromasaje </a>
											<span>La acción del calor acompañado de un masaje con chorros de agua y aire ayuda al flujo sanguíneo a circular con mas facilidad aliviando cefaleas tensionales, enfermedades como artrosis, artritis, tendinitis y lumbalgia.</span>
										</div>
									</div>
								</div>	


							</div>
							
							<a className="left carousel-control" href="#carousel-reviews-facilities" role="button" data-slide="prev">
								<span className="glyphicon glyphicon-chevron-left"></span>
							</a>
							<a className="right carousel-control" href="#carousel-reviews-facilities" role="button" data-slide="next">
								<span className="glyphicon glyphicon-chevron-right"></span>
							</a>
						</div>
					</div>
				</div>
			</div>
		
		</section>
		</>
    )
}

export default Facilities;