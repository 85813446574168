
import React from 'react'

const NewTestimonial = () => {
  return (
      <>
        <section id="testimonios-clientes" className="home-section paddingbot-60 parallax" data-stellar-background-ratio="0.5">
            <div className='container'>
                <h3 style={{ color :'white' }}>Obtén mas información sobre Fisiothera+ en Google.</h3>
                <h4 >
                    <a className='unico' target={ "_blank" } style={{ color :'white' , }} href='https://g.page/r/Cf8Qz06gm7gxEA0'>
                        Click aquí
                    </a>
                </h4>
            </div>
        </section>
      </>
  )
}


export default NewTestimonial;