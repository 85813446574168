
import React from 'react';

const Home = () => {

    return (
		<>
    
    <section id="clínica-de-fisioterapia-y-rehabilitación-fisiothera+" className="home-section">

		
<div className="carousel-review broun-block">
    <div className="container">
        <div className="row">
            <div id="carousel-review" className="carousel slide" data-ride="carousel">
            
                <div className="carousel-inner">
                    <div className="item active">
                	    <div className="col-md-12 col-sm-6">
							<div className="person-text-dos rel text-light">					
								<img src="img/init/inicio.png" alt="" className="person" />
							</div>
						</div>           		
						
                    </div>
					<div className='item'>
						<div className="col-md-12 col-sm-6 ">
							<div className="person-text-dos rel text-light">
								<img src="img/init/inicio2.jpg" alt="" className="person" />
							</div>
						</div>
					</div>
					<div className='item'>
						<div className="col-md-12 col-sm-6 ">
							<div className="person-text-dos rel text-light">
				                <img src="img/init/inicio3.jpeg" alt="" className="person" />
							</div>
						</div>
					</div>
                    <div className="item">
                        <div className="col-md-12 col-sm-6">
							<div className="person-text-dos rel text-light">
								<img src="img/init/inicio4.png" alt="" className="person" />
							</div>
						</div>            			
                    </div>
					<div className='item'>
						<div className="col-md-12 col-sm-6 ">
							<div className="person-text-dos rel text-light">
								<img src="img/init/inicio5.jpg" alt="" className="person" />
							</div> 
						</div>
						
					</div>
                    
					<div className='item'>
						<div className="col-md-12 col-sm-6">
							<div className="person-text-dos rel text-light">
								<img src="img/init/inicio6.jpg" alt="" className="person" />
							</div>
						</div>
					</div>
                   
                </div>
				
                <a className="left carousel-control" href="#carousel-review" role="button" data-slide="prev">
                    <span className="glyphicon glyphicon-chevron-left"></span>
                </a>
                <a className="right carousel-control" href="#carousel-review" role="button" data-slide="next">
                    <span className="glyphicon glyphicon-chevron-right"></span>
                </a>
            </div>
        </div>
    </div>
</div>   
		
    </section>

	<section id="inicio-fisiothera" className="intro">
	<div className="intro-content">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="" data-wow-offset="0" data-wow-delay="0.1s">
					<h2 className="h-ultra">Clínica de Fisioterapia y Rehabilitación</h2>
					</div>
					<div className="" data-wow-offset="0" data-wow-delay="0.1s">
					<h4 className="h-light">
						Somos una 
						<span className="color">clínica especializada y exclusiva</span>
						.Su alivio, salud y bienestar físico son nuestra mayor motivación.
					</h4>
					</div>
						


					</div>
									
				</div>		
				<div className='row' style={{ textAlign :'center'}}>
					<div className='col-lg-4'>
						<h4 className="h-bold">Misión</h4>
						<div className="well well-trans">
						<p style={{ fontWeight : 'bold' , textAlign:'justify'}}>
						Fisiothera+ es una empresa dedicada a brindar servicios de rehabilitación a pacientes
						con alguna afectación física, neurológica, congénita o adquirida, a través de un equipo
						multidisciplinario en el área de medicina física ampliamente capacitado. Es nuestra
						prioridad dar un amplio servicio a nuestros clientes, acoplándonos a sus necesidades y
						planificando un plan de tratamiento en casa acorde a las posibilidades de cada uno de
						nuestros pacientes para una pronta recuperación.
						</p>
						</div>
						
					</div>
					<div className='col-lg-4'>
						<h4 className="h-bold">Visión</h4>
						<div className="well well-trans">
							<p style={{ fontWeight : 'bold',textAlign:'justify'}}>
							Ser una empresa modelo a nivel nacional que promueva la importancia de los servicios
							de la rehabilitación física como parte de la prevención y atención de cualquier tipo de
							lesión que pueda restringir las capacidades físicas de una persona, mejorando así su
							calidad de vida.
							</p>
						</div>
					</div>
					<div className='col-lg-4' style={{ textAlign :'left'}}>
						<h4 className="h-bold">Valores</h4>
						<div className="well well-trans">
						<div className="" data-wow-delay="0.1s">

						<ul className="lead-list">
							<li><span className="fa fa-check fa-2x icon-success"></span> <span className="list">
								<strong>Humanismo.</strong></span></li>
							<li><span className="fa fa-check fa-2x icon-success"></span> <span className="list">
								<strong>Discreción.</strong></span></li>
							<li><span className="fa fa-check fa-2x icon-success"></span> <span className="list">
								<strong>Igualdad.</strong></span></li>
							<li><span className="fa fa-check fa-2x icon-success"></span> <span className="list">
								<strong>Excelencia.</strong></span></li>
							<li><span className="fa fa-check fa-2x icon-success"></span> <span className="list">
								<strong>Responsabilidad.</strong></span></li>
							<li><span className="fa fa-check fa-2x icon-success"></span> <span className="list">
								<strong>Empatía.</strong></span></li>
							<li><span className="fa fa-check fa-2x icon-success"></span> <span className="list">
								<strong>Servicio.</strong></span></li>
							<li><span className="fa fa-check fa-2x icon-success"></span> <span className="list">
								<strong>Trabajo en equipo.</strong></span></li>
						</ul>

						</div>
						</div>
					</div>
				</div>

				
			</div>
		</div>		
	</section>

	<section id="promo" className="home-section paddingtop-80">
		<div className='row' style={{ textAlign : 'center'}}>
			<div className='col-lg-3'>
			</div>
			<div className='col-lg-6'>
				<div className="well well-trans">
				<div className="" data-wow-delay="0.1s">

				<ul className="lead-list">
					<li><span className="fa fa-check fa-2x icon-success"></span> <span className="list">
						<strong>Atención personalizada.</strong></span></li>
					<li><span className="fa fa-check fa-2x icon-success"></span> <span className="list">
						<strong>Equipos de alta tecnología.</strong></span></li>
					<li><span className="fa fa-check fa-2x icon-success"></span> <span className="list">
						<strong>Técnicas manuales novedosas y seguras.</strong></span></li>
				</ul>

				</div>
				</div>
				
			</div>
			<div className='col-lg-3'>
			</div>
		</div>
	</section>

	</>
	        
    )
}

export default Home;