import React from 'react'

const Boxes = () => {

    return (
        
    <section id="boxes" className="home-section paddingtop-80">
	
		<div className="container">
			<div className="row">
				<div className="col-sm-3 col-md-3">
					<div className="" data-wow-delay="0.2s">
						<div className="box text-center">
							
							<i className="fa fa-check fa-3x circled bg-skin"></i>
							<h4 className="h-bold">Haga una cita</h4>
							<p>
							Escoja una fecha y horario de su conveniencia y solicite una cita en nuestro consultorio. Luego le contactaremos para confirmar.
							</p>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-3">
					<div className="" data-wow-delay="0.2s">
						<div className="box text-center">
							
							<i className="fa fa-list-alt fa-3x circled bg-skin"></i>
							<h4 className="h-bold">Nuestros tratamientos</h4>
							<p>
							Mediante una valoración inicial la terapeuta establecerá el tratamiento, las recomendaciones, los ejercicios
							y los objetivos según las necesidades del paciente.
							</p>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-3">
					<div className="" data-wow-delay="0.2s">
						<div className="box text-center">
							<i className="fa fa-user-md fa-3x circled bg-skin"></i>
							<h4 className="h-bold">Consulte a un especialista</h4>
							<p>
							Realizamos tramientos individualizados según las necesidades y objetivos de cada paciente;en habitaciones 
							privadas y totalmente equipadas.
							</p>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-3">
					<div className="" data-wow-delay="0.2s">
						<div className="box text-center">
							
							<i className="fa fa-hospital-o fa-3x circled bg-skin"></i>
							<h4 className="h-bold">Informe diagnostico</h4>
							<p>
							Contamos con los mejores especialistas para llevar a cabo su tramiento de la mejor manera posible.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

	</section>
    );
}

export default Boxes;