import React from 'react'

const Service = () => {

    return (
        <>
        <section id="callaction" className="home-section paddingtop-40">	
           <div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="callaction bg-gray">
							<div className="row">
								<div className="col-md-8">
									<div className="" data-wow-delay="0.1s">
									<div className="cta-text">
									<h3>¿Es una emergencia? ¿Necesitas ayuda?</h3>
									<p>Agende una cita de valoración para poder iniciar su tratamiento.</p>
									</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="" data-wow-delay="0.1s">
										<div className="cta-btn">
										<a href="https://wa.me/7774497611?text=%C2%A1Hola!%20buen%20día,%20me%20gustaría%20solicitar%20informes%20acerca%20de..." 
										target={"_blank"} rel="noreferrer" className="btn btn-skin btn-lg">Reserve una cita</a>	
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
	</section>	
	

    <section id="servicios-de-fisioterapia" className="home-section nopadding paddingtop-60">

		<div className="container">

        <div className="row">
			
			<div className="col-sm-4 col-md-4">
				<div className="" data-wow-delay="0.2s">
				<img src="img/dummy/img-1.jpg" className="img-responsive" alt="" />
				</div>
            </div>
			
			<div className="col-sm-4 col-md-4" style={{ textAlign : 'justify'}}>
				
				<div className="" data-wow-delay="0.1s">
                <div className="service-box">
					<div className="service-icon">
						<span className="fa fa-stethoscope fa-3x"></span> 
					</div>
					<div className="service-desc">
						<h5 className="h-light">Fisioterapia Pediátrica</h5>
						<p>Consiste en el asesoramiento, tratamiento y cuidado de aquellos bebés, niños/as y adolescentes que presentan un retraso general en su desarrollo, desórdenes en el movimiento (tanto congénitos como adquiridos) o que tienen riesgo de padecerlos.</p>
					</div>
                </div>
				</div>
				
				<div className="" data-wow-delay="0.2s">
				<div className="service-box">
					<div className="service-icon">
						<span className="fa fa-wheelchair fa-3x"></span> 
					</div>
					<div className="service-desc">
						<h5 className="h-light">Fisioterapia Reumatológica</h5>
						<p>Se encarga de realizar tratamientos individuales y personalizados para aliviar el dolor de los trastornos del aparato locomotor y del tejido conectivo como problemas de artrosis, artritis, osteoporosis, fibromialgia, espondilitis anquilosante, entre otras.</p>
					</div>
                </div>
				</div>
				<div className="" data-wow-delay="0.3s">
				<div className="service-box">
					<div className="service-icon">
						<span className="fa fa-plus-square fa-3x"></span> 
					</div>
					<div className="service-desc">
						<h5 className="h-light">Fisioterapia Ortopédica/Traumatológica</h5>
						<p>Consiste en el tratamiento de lesiones músculo-esqueléticas, en articulaciones, músculos, huesos, tejidos blandos y/o de los nervios. Por ejemplo:  Lesiones de ligamentos (esguinces, roturas), Lesiones musculares, de cartílagos, Luxaciones, Fracturas, Degeneración articular (artritis, artrosis) y Lesiones que requieren cirugía (preoperatorio y postoperatorio).</p>
					</div>
                </div>
				</div>


            </div>
			<div className="col-sm-4 col-md-4" style={{ textAlign :'justify'}}>
				
				<div className="" data-wow-delay="0.1s">
                <div className="service-box">
					<div className="service-icon">
						<span className="fa fa-h-square fa-3x"></span> 
					</div>
					<div className="service-desc">
						<h5 className="h-light">Fisioterapia Geriátrica</h5>
						<p>Se encarga de la prevención y tratamiento de lesiones o patologías relacionada con la edad con el objetivo de retrasar su avance como enfermedades óseas (osteoporosis o la artritis), enfermedades renales como la incontinencia urinaria, lesiones propias de la edad como reemplazos articulares o las fracturas de cadera, así como Mejorar la coordinación y el equilibrio, reduciendo el riesgo de lesiones producidas por caídas.</p>
					</div>
                </div>
				</div>
				
				<div className="" data-wow-delay="0.2s">
				<div className="service-box">
					<div className="service-icon">
						<span className="fa fa-filter fa-3x"></span> 
					</div>
					<div className="service-desc">
						<h5 className="h-light">Fisioterapia Neurológica</h5>
						<p>Se encarga del tratamiento de las alteraciones causadas por la afectación del sistema nervioso central o periférico, poniendo a disposición del paciente una serie de técnicas dirigidas a mejorar, la calidad y la eficacia de los movimientos, el control postural, la marcha, la estabilidad, reducir la espasticidad, los temblores y la fatiga.</p>
					</div>
                </div>
				</div>
				<div className="" data-wow-delay="0.3s">
				<div className="service-box">
					<div className="service-icon">
						<span className="fa fa-user-md fa-3x"></span> 
					</div>
					<div className="service-desc">
						<h5 className="h-light">Estimulacón temprana en bebés</h5>
						<p>Consiste en activar en los recién nacidos una serie de funciones cerebrales que les permitan mejorar su desarrollo y contribuir para potenciar sus habilidades psicomotoras mediante actividades adaptadas a su edad.</p>
					</div>
                </div>
				</div>

            </div>
			
        </div>		
		</div>
	</section>
    </>
    );
}

export default Service;