
import './App.css';
import Header from './components/Header';
import Boxes from './pages/Boxes';
import Facilities from './pages/Facilities';
import Footer from './pages/Footer';
import Home from './pages/Home';
import NewTestimonial from './pages/NewTestimonial';
import Service from './pages/Services';
import Team from './pages/Team';
import Testimonial from './pages/Testimonial';



function App() {
  return (
    <div>
        <Header/>
        <Home />
        <Boxes />
        <Service/>
        { 
          //<Team/>
          //<Testimonial/>
        }
        <Facilities/>
        <NewTestimonial/>
        <Footer/>
    </div>
  );
}

export default App;
