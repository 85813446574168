


const Header = () => {

    return (
		<>
		  <nav className="navbar navbar-custom navbar-fixed-top" role="navigation">
		<div className="top-area">
			<div className="container">
				<div className="row">
					<div className="col-sm-6 col-md-6">
					<p className="bold text-left">Lunes - Sabado de 8:00am a 18:00pm </p>
					</div>
					<div className="col-sm-6 col-md-6">
					<p className="bold text-right">
						LLamanos +52 777-449-76-11 &nbsp;&nbsp;&nbsp;
						<a href="https://wa.me/7774497611?text=%C2%A1Hola!%20buen%20día,%20me%20gustaría%20solicitar%20informes%20acerca%20de..." target={'_blank'}>
							<img style={{ borderRadius:'3px'}} src='/img/whatsapp.png' height={ '39px' } width={'39px'} />
						</a>
					</p>
					</div>
				</div>
			</div>
		</div>
        <div className="container navigation">
		
            <div className="navbar-header page-scroll">
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-main-collapse">
                    <i className="fa fa-bars"></i>
                </button>
                <a className="navbar-brand" href="clínica-de-fisioterapia-y-rehabilitación-fisiothera+">
					{
						<img src="img/fisiotera-letra.png" alt="" width="300" height="50" />
					}
                </a>
            </div>

            <div className="collapse navbar-collapse navbar-right navbar-main-collapse">
			  <ul className="nav navbar-nav">
				<li className="active"><a href="clínica-de-fisioterapia-y-rehabilitación-fisiothera+">CLÍNICA</a></li>
				<li><a href="#inicio-fisiothera">INICIO</a></li>
				<li><a href="#servicios-de-fisioterapia">SERVICIOS</a></li>
				<li><a href="#nuestras-instalaciones">INSTALACIONES</a></li>
				<li><a href="#testimonios-clientes">TESTIMONIOS</a></li>
			  </ul>
            </div>
         </div>
    	</nav>
		</>
    )
}

export default Header;